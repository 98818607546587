
.coach-selector-container {
    margin: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* Container with fancy border */
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1rem;
    width: fit-content;
    margin: 0 auto;

}


.coach-selector-row {
    display: flex;
}

.coach-selector-tag-picker {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    width: 15rem;
}
